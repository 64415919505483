import { navigate } from "gatsby-link";
import {
  Box,
  Image,
  ResponsiveContext,
  TextInput,
  Button,
  Text,
  Heading,
} from "grommet";
import React, { useState } from "react";
import Layout from "../components/elements/layout";
import mainLogo from "../images/logo.png";
import { changePassword } from "../services/login_service";
import { getUser } from '../services/storage_service';

const ChangePasswordPage = ({ size, logo }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const doChangePassword = async () => {

    if (!currentPassword || currentPassword.length < 6) {
      setError("Mot de passe actuel non valid");
      return;
    }

    if (!password || password.length < 6) {
      setError("Mot de passe au moin 6 caractères");
      return;
    }

    if (
      password !== passwordConfirm
    ) {
      setError("La confirmation de mot de passe ne correspond pas");
      return;
    }

    setError("");
    setLoading(true);
    changePassword(currentPassword, password)
      .then((res) => {
        const s = res.data;
        if (s.success) {
          alert(
            "Votre mot de passe est bien enregistré."
          );
          navigate("/home");
        } else {
          setError("Le mot de passe actuel non valid");
          setLoading(false);
        }
      })
      .catch((err) => {
        setError("Identifiant non trouvé ou le mot de passe actuel non valid");
        setLoading(false);
      });
  };

  const inputStyle = {
    background: "#EDEDED",
    fontSize: 16,
    borderRadius: 10,
    border: "none",
  };
  return (
    <Layout
      style={{ justifyContent: "center", alignContent: "center" }}
      hideHeader="true"
      hideFooter="true"
    >
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box
            justify="center"
            overflow="auto"
            direction="column"
            alignContent="center"
            alignSelf="center"
            height="100vh"
          >
            <Box
              width="medium"
              alignSelf="center"
              justify="center"
              align="center"
              alignContent="center"
            >
              <Image src={logo || mainLogo} margin="small" />
              <Heading
                level="3"
                style={{ color: "#707070" }}
                textAlign="center"
              >
                Changer votre mot de passe
              </Heading>
              {/* <Text
                level="5"
                weight="bold"
                style={{ color: "#707070", marginBottom: 10 }}
                textAlign="center"
              >
                {getUser() !== '' ? getUser().email : ''}
              </Text> */}
              <Text
                level="5"
                style={{ color: "#707070", marginBottom: 30 }}
                textAlign="center"
              >
                Pour changer votre mot de passe, saisissez le mot de passe que
                vous utilisez pour vous connecter à votre compte My Trustiway.
              </Text>

              <Text
                size="small"
                style={{ color: "#dc3545", marginBottom: 10 }}
                textAlign="center"
              >
                {error}
              </Text>
              <Box width="large" gap="small">
                <TextInput
                  placeholder="Mot de passe actuel *"
                  type="password"
                  onChange={(event) => setCurrentPassword(event.target.value)}
                  style={inputStyle}
                />
                <TextInput
                  placeholder="Nouveau mot de passe *"
                  type="password"
                  onChange={(event) => setPassword(event.target.value)}
                  style={inputStyle}
                />
                <TextInput
                  placeholder="Confirmation de mot de passe *"
                  type="password"
                  onChange={(event) => setPasswordConfirm(event.target.value)}
                  style={inputStyle}
                />
                <Box width="small" alignSelf="center">
                  <Button
                    primary
                    label="Envoyer"
                    onClick={doChangePassword}
                    disabled={loading}
                  />
                </Box>
                <Box width="large" align="center">
                  <Button
                    onClick={() => {
                      navigate("/home");
                    }}
                  >
                    <Text
                      style={{ color: "#707070", fontSize: 14 }}
                      textAlign="center"
                    >
                      Retour à l'accueil
                    </Text>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Layout>
  );
};

export default ChangePasswordPage;
